<template>
  <app-view>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary pb-50 mt-2">
      {{ $t('pages.crops.name') }}
    </h1>
    <h4
      class="text-ellipsis"
    > {{ $t('pages.crops.add-edit.name') }}</h4>

    <div class="mt-0 pt-75 mb-3">
      <template v-if="loader">
        <div class="mt-2 d-flex align-items-center justify-content-center my-5 py-2">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="mt-1"
        >
          <b-row>
            <b-col
              class="mb-1"
            >
              <div
                v-if="loader"
                class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
              >
                <b-spinner
                  variant="primary"
                  type="grow"
                  :label="$t('therms.loading')"
                />
              </div>
              <template v-else>
                <validation-observer
                  ref="addForm"
                  #default="{invalid}"
                >
                  <b-form
                    class="animate__animated"
                    :class="{'animate__shakeX':error}"
                    @submit.prevent="submit"
                  >

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.type')+' : '"
                      label-for="crop_family_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="crop_family_id"
                        vid="crop_family_id"
                      >
                        <b-form-select
                          id="crop_family_id"
                          v-model="crop_family_id"
                          size="lg"
                          :options="cropFamiliesOptions"
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.name')+' : '"
                      label-for="name"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        vid="name"
                        rules="required|min:1|max:45"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          size="lg"
                          minlength="1"
                          maxlength="45"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('pages.crops.add-edit.form.name.placeholder')"
                        />
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.color')+' : '"
                      label-for="color"
                    >
                      <div class="position-relative mb-50">
                        <div class="d-flex">
                          <div class="color">
                            <span
                              :style="{ 'background-color': color}"
                              @click="chooseColor"
                            />
                          </div>
                          <div class="ml-1 d-flex align-items-start flex-column justify-content-around">
                            <p class="mb-25 font-small-2">
                              {{ color }}
                            </p>
                            <TouchButton
                              size="xs"
                              color="primary"
                              type="button"
                              @action="chooseColor"
                            >
                              {{ $t('pages.crops.add-edit.form.color.label') }}
                            </TouchButton>
                          </div>
                        </div>
                        <input
                          id="color"
                          ref="colorPicker"
                          v-model="color"
                          style="opacity: 0; position: absolute; bottom: -20px; z-index: -100;"
                          name="color"
                          type="color"
                          maxlength="7"
                          placeholder="#000000"
                        >
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-size="lg"
                      :label="$t('therms.icon')+' : '"
                      label-for="emoji"
                    >
                      <div class="emojis">
                        <div class="mb-2 d-flex">
                          <span
                            class="selected"
                            @click="showEmojiGallery = true"
                          >{{ emoji }}</span>
                          <div
                            v-if="selectedEmoji !== null"
                            class="ml-1 d-flex align-items-start flex-column justify-content-around"
                          >
                            <p class="mb-25">
                              {{ $voca.capitalize(selectedEmoji.description) }}
                            </p>
                            <div>

                              <TouchButton
                                size="xs"
                                color="danger"
                                type="button"
                                class="mr-50 mb-50"
                                @action="removeEmoji"
                              >
                                {{ $t('therms.delete') }}
                              </TouchButton>
                              <TouchButton
                                size="xs"
                                color="secondary"
                                type="button"
                                @action="showEmojiGallery = !showEmojiGallery"
                              >
                                {{ showEmojiGallery ? $t('therms.hide-icons') : $t('therms.show-icons') }}
                              </TouchButton>
                            </div>
                          </div>
                          <div
                            v-else
                            class="ml-1 d-flex align-items-start flex-column justify-content-around"
                          >
                            <p class="mb-25 font-small-2">
                              {{ $t('therms.no-icon') }}
                            </p>
                            <TouchButton
                              v-if="!showEmojiGallery"
                              size="xs"
                              color="primary"
                              type="button"
                              @action="showEmojiGallery = true"
                            >
                              {{ $t('pages.crops.add-edit.form.icon.options.select') }}
                            </TouchButton>
                            <TouchButton
                              v-else
                              size="xs"
                              color="warning"
                              type="button"
                              @action="showEmojiGallery = false"
                            >
                              {{ $t('pages.crops.add-edit.form.icon.options.remove') }}
                            </TouchButton>
                          </div>
                        </div>

                        <transition name="scale-in">
                          <div v-if="showEmojiGallery">
                            <b-form-group
                              label-size="lg"
                              :label="$t('pages.crops.add-edit.form.icon.label')+' : '"
                              label-for="emoji"
                              class="mb-1"
                            >
                              <b-form-input
                                id="emoji"
                                v-model="searchEmoji"
                                autocorrect="off"
                                autocapitalize="none"
                                size="sm"
                                :placeholder="$t('therms.search')"
                                style="border-radius: 0.628rem"
                              />
                            </b-form-group>

                            <div
                              v-if="searchEmoji.length < 2"
                              class="list"
                            >
                              <div
                                class="d-flex flex-wrap mb-75"
                              >
                                <div
                                  v-for="(category, index) in groupedEmojis"
                                  :key="index"
                                  class="category mb-25 mr-1 text-uppercase font-small-3"
                                  :class="{'text-primary' : showEmojiGroup === category.name, 'text-black-50' : showEmojiGroup !== category.name}"
                                  @click="showEmojiGroup = category.name"
                                >
                                  {{ category.name }}
                                </div>
                              </div>

                              <div
                                v-for="(category, index) in groupedEmojis"
                                :key="index"
                              >
                                <template v-if="showEmojiGroup === category.name ">
                                  <TouchButton
                                    v-for="(icon, key) in category.emojis"
                                    :key="key"
                                    :class="{'hold': emoji === icon.emoji}"
                                    color="transparent"
                                    type="button"
                                    @action="emoji = icon.emoji"
                                  >
                                    {{ icon.emoji }}
                                  </TouchButton>
                                </template>
                              </div>
                            </div>
                            <div
                              v-if="searchEmoji.length >=2"
                              class="list"
                            >
                              <TouchButton
                                v-for="(icon, key) in emojis"
                                :key="key"
                                :class="{'hold': emoji === icon.emoji}"
                                color="transparent"
                                type="button"
                                @action="emoji = icon.emoji"
                              >
                                {{ icon.emoji }}
                              </TouchButton>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </b-form-group>

                    <div class="mt-2 pt-75">
                      <touch-button
                        size="sm"
                        :disabled="invalid || error"
                        :disable="invalid || error"
                      >
                        {{ $t('therms.save') }}
                      </touch-button>
                    </div>
                  </b-form>
                </validation-observer>
              </template>
            </b-col>
          </b-row>

        </div>
      </template>
    </div>
  </app-view>
</template>

<script>
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import {
  BSpinner,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { max, min, required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import { NAME_CROP_ALL } from '@/router/routes/crops'
import { Dialog } from '@capacitor/dialog'
import emojiGallery from '@/libs/emoji'
import collect from 'collect.js'

export default {
  components: {
    NavButtonBar,
    NavButton,
    TouchButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,
      showEmojiGallery: false,
      showEmojiGroup: null,
      favoriteEmojiCategory: null,
      searchEmoji: '',

      // data
      name: '',
      emoji: null,
      color: '#000000',
      crop_family_id: null,

      // validation rules
      required,
      max,
      min,
    }
  },
  computed: {
    ...mapGetters({
      has: 'crops/has',
      cropFamilies: 'cropFamilies/getList',
    }),
    /**
     * @returns {Array<Crop>}
     */
    cropFamiliesOptions() {
      return this.cropFamilies.map(cf => ({
        value: cf.id,
        text: cf.name,
      }))
    },
    selectedEmoji() {
      const selection = emojiGallery
        .filter(e => e.emoji === this.emoji)

      if (selection.length <= 0) return null
      return selection[0]
    },
    emojis() {
      return emojiGallery
        .filter(e => e.category.toLowerCase().indexOf(this.searchEmoji.toLowerCase()) !== -1 || e.description.toLowerCase().indexOf(this.searchEmoji.toLowerCase()) !== -1)
    },
    groupedEmojis() {
      return (
        collect(emojiGallery)
          .groupBy('category')
          .keys()
          .values()
          .all()
      ).map(category => ({
        name: category,
        emojis: this.emojis
          .filter(e => e.category === category)
          .map(e => ({
            emoji: e.emoji,
            description: e.description,
          })),
      }))
    },
  },
  watch: {
    error() {
      setTimeout(() => {
        if (this.error) {
          this.error = false
        }
      }, 800)
    },
  },
  async mounted() {
    this.error = false
    this.crop_family_id = this.cropFamiliesOptions[0].value
    this.favoriteEmojiCategory = this.$voca.slugify(this.$t('emojis.categories.food-drink'))
    this.resetEmojiCategorySelection(true)
  },
  methods: {
    async submit() {
      try {
        this.loader = true
        const result = await this.$store.dispatch('crops/add', {
          name: this.name,
          emoji: this.emoji,
          color: this.color,
          crop_family_id: this.crop_family_id,
        })

        if (!result) {
          this.error = true
          await Dialog.alert({
            title: this.$t('therms.error').toString(),
            message: this.$t('actions.crop-add-error.message').toString(),
            buttonTitle: this.$t('therms.close').toString(),
          })
          return
        }

        await this.$router.push({
          name: NAME_CROP_ALL,
        })
      } catch (err) {
        this.error = true
        throw err
      } finally {
        this.loader = false
      }
    },
    removeEmoji() {
      this.emoji = null
      this.showEmojiGallery = false
      this.resetEmojiCategorySelection()
    },
    resetEmojiCategorySelection(favorite = false) {
      if (favorite) {
        const f = this.groupedEmojis.filter(e => this.$voca.slugify(e.name) === this.favoriteEmojiCategory)

        if (f.length >= 1) {
          this.showEmojiGroup = f[0].name
          return
        }
      }

      this.showEmojiGroup = this.groupedEmojis[0].name
    },
    chooseColor() {
      this.$refs.colorPicker.blur()
      setTimeout(() => {
        this.$refs.colorPicker.click()
        this.$refs.colorPicker.focus()
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

.color {
  border-radius: 0.828rem;
  border: 1px solid #d8d6de;
  background-color: #ffffff;
  min-height: 58px;
  min-width: 58px;
  max-height: 58px;
  max-width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;

  span {
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    border-radius: 34px;
    display: block;
  }
}

.emojis {
  .selected {
    border-radius: 0.828rem;
    border: 1px solid #d8d6de;
    background-color: #ffffff;
    min-height: 58px;
    min-width: 58px;
    max-height: 58px;
    max-width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
  }

  .list {
    width: 100%;
    //max-height: calc(calc(38px * 10) + 1px);
    //overflow: scroll;
    //background-color: rgba(0, 0, 0, 0.03);
    //border-radius: 0.6rem;

    > div {
      display: flex;
      flex-wrap: wrap;
    }

    button {
      min-height: 38px;
      max-height: 38px;
      min-width: 16.6%;
      max-width: 16.6%;

      @media only screen and (min-width: 375px) {
        min-width: 12.5%;
        max-width: 12.5%;
      }
      @media only screen and (min-width: 768px) {
        min-width: 8.33%;
        max-width: 8.33%;
      }
      @media only screen and (min-width: 1024px) {
        min-width: 6.25%;
        max-width: 6.25%;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8em;
    }
  }
}

</style>
